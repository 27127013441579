import Swiper from './swiper.min.js';



// якорь
$('.menu__nav li').on('click','a', function(event) {
  slideChange = false;
  event.preventDefault();
  $('body').removeClass('hidden');
  $('.header').removeClass('active');
  $('.menu').removeClass('active');
  var id = $(this).attr('href'),
    top = $(id).offset().top;
  $('body,html').animate({scrollTop: top}, 1500);
  setTimeout(function() {
    slideChange = true;
  }, 1600);
  
});







$(document).on('scroll', function() {
  if($('.why').offset().top <= $(this).scrollTop() && ($('.why').offset().top + $('.why').outerHeight() - $(window).height()) >= $(this).scrollTop()) {
    $('.why__fix').addClass('fixed');
  } else{
    $('.why__fix').removeClass('fixed');
  }

  //top
  if($(this).scrollTop() > ($('.why').offset().top + $('.why').outerHeight() - $(window).height())) {
    var top = $('.why').outerHeight() - $(window).height();
    $('.why__fix').css({'top': top});
  }
  if($(this).scrollTop() < $('.why').offset().top) {
    $('.why__fix').css({'top': '0'});
  }
});



//scroll active slide
$('#why').height($('.why__info__block li').length + '00vh');

//home
var swiper = new Swiper('.swiper-activity', {
  spaceBetween: 15,
  slidesPerView: 'auto',
  loop: true,
  navigation: {
    nextEl: '.swiper-activity-next',
    prevEl: '.swiper-activity-prev',
  },
});


//fixed block
var swiper2 = new Swiper('.swiper-why', {
  direction: 'vertical',
  slidesPerView: 'auto',
  spaceBetween: 0,
  mousewheel: true,
  loop: true,
  // loopedSlides: 3,
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-why-next',
    prevEl: '.swiper-why-prev',
  },
});


//change swiper
var slideChange = true;

swiper2.on('slideChange', function() {
  $('.why__index').text(swiper2.realIndex + 1);
  $('.why__info__block li').removeClass('active');
  $('.why__info__block li').eq(swiper2.realIndex).addClass('active');
  if(slideChange) {
    if(swiper2.realIndex === 0) {
      var top = $('.why').offset().top;
      $('body,html').animate({scrollTop: top}, 0);
    } else if(swiper2.realIndex === 1) {
      var top = $('.why').offset().top + $(window).height();
      $('body,html').animate({scrollTop: top}, 0);
    }else {
      console.log(swiper2.realIndex);
      var top = $('.why').offset().top + $(window).height() * swiper2.realIndex;
      $('body,html').animate({scrollTop: top}, 0);
    }
  }
});







//click to slide
$('.why__nav__block').on('click', function() {
  swiper2.slideToLoop(+$(this).find('span').text() - 1);
});



var scrollSlide = $(document).on('scroll', function() {
  var scrTop = $(this).scrollTop() + $(window).height() / 2;
  if(scrTop > $('.why').offset().top && scrTop < ($('.why').offset().top + $(window).height())) {
    swiper2.slideToLoop(0);
  } else if(scrTop > $('.why').offset().top + $(window).height() && scrTop < ($('.why').offset().top + $(window).height() * 2) && $('.why__info__block li').length >= 2) {
    swiper2.slideToLoop(1);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 2 && scrTop < ($('.why').offset().top + $(window).height() * 3) && $('.why__info__block li').length >= 3) {
    swiper2.slideToLoop(2);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 3 && scrTop < ($('.why').offset().top + $(window).height() * 4) && $('.why__info__block li').length >= 4) {
    swiper2.slideToLoop(3);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 4 && scrTop < ($('.why').offset().top + $(window).height() * 5) && $('.why__info__block li').length >= 5) {
    swiper2.slideToLoop(4);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 5 && scrTop < ($('.why').offset().top + $(window).height() * 6) && $('.why__info__block li').length >= 6) {
    swiper2.slideToLoop(5);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 6 && scrTop < ($('.why').offset().top + $(window).height() * 7) && $('.why__info__block li').length >= 7) {
    swiper2.slideToLoop(6);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 7 && scrTop < ($('.why').offset().top + $(window).height() * 8) && $('.why__info__block li').length >= 8) {
    swiper2.slideToLoop(7);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 8 && scrTop < ($('.why').offset().top + $(window).height() * 9) && $('.why__info__block li').length >= 9) {
    swiper2.slideToLoop(8);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 9 && scrTop < ($('.why').offset().top + $(window).height() * 10) && $('.why__info__block li').length >= 10) {
    swiper2.slideToLoop(9);
  }else if(scrTop > $('.why').offset().top + $(window).height() * 10 && scrTop < ($('.why').offset().top + $(window).height() * 11) && $('.why__info__block li').length >= 11) {
    swiper2.slideToLoop(10);
  }



});
