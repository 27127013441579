import cookie from 'jquery.cookie';

//lang
$('.intro__lang__btn').on('click', function() {
  $(this).siblings('ul').fadeToggle();
});

//menu
$('.header__menu a').on('click', function(event) {
  event.preventDefault();
  $('body').toggleClass('hidden');
  $('.header').toggleClass('active');
  $('.menu').toggleClass('active');
});



//cookie
if($.cookie('use_cookie') === undefined) {
  $('.cookie').removeClass('hidden');
}

$('.cookie a').on('click', function(event) {
  event.preventDefault();
  $('.cookie').addClass('hidden');
  $.cookie('use_cookie', 'false', { expires: 360 });
});



//number step
var animation = false;
$(document).on('scroll', function() {
  if($(this).scrollTop() >= ($('.info').offset().top - $(window).height() / 2) && !animation) {
    animation = true;
    $('.info__block b').addClass('active');
    $('.info__block b').each(function() {
      $(this).prop('Counter',0).animate({
        Counter: $(this).text()
      }, {
        duration: 2000,
        easing: 'swing',
        step: function(now) {
          $(this).text(Math.ceil(now));
        }
      });
    });
  }
  
});


//open popup
$('.popup-open').on('click', function(event) {
  event.preventDefault();
  var href = $(this).attr('href');
  $(href).fadeIn();
  $('.popup__bg').fadeIn();
});

//close popup
$('.popup__close, .popup__bg').on('click', function() {
  var href = $(this).attr('href');
  $('.popup').fadeOut();
  $('.popup__bg').fadeOut();
});
